import * as React from 'react';
import { withLayout } from '../../components/layout.tsx';
import { Header, Container } from 'semantic-ui-react';

class Training extends React.Component<{ data: any }, {}> {
  public render() {
    return (
      <>
        <div
          css={{
            '@media only screen and (max-width: 500px)': {
              marginLeft: '2ex',
              marginRight: '2ex',
            },
          }}
        >
          <Container text textAlign="center">
            <Header
              as="h1"
              css={{
                fontSize: '250% !important',
                marginTop: '0px !important',
                marginBottom: '1em !important',
              }}
            >
              <Header.Content>On-Site and Remote Training</Header.Content>
            </Header>
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>
            Salvus is an expert level tool and a certain amount of knowledge and
            training is necessary to get the most out of it.
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>
            To aid in that <b>we offer on-site and remote training</b>,
            typically arranged as 3 to 5 day workshops with two instructors.
            Class sizes from 5 to 20 people are optimal.
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>
            A 3 to 5 day Salvus workshop at your venue with two instructors.
            Typically, we cover full waveform modeling and inversion from theory
            to applications, involving comprehensive hands-on sessions with the
            Salvus software suite. The workshop schedule is flexible and the
            contents can be adapted to your specific needs. The required
            computations can either be done using cloud-based services provided
            by Mondaic or on your local hardware. The main incentives of the
            workshop are to provide insights into the underlying physics and
            numerical methods, to accelerate the learning curve, and to give
            users a head start to use Salvus for their own work.
          </Container>
        </div>
      </>
    );
  }
}

export default withLayout(Training);
